import api from './api'

const API = '/api/tender_notices'

export const IttFramework = {
  get: function(params) {
    return api.get(API, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  activeFramework: function() {
    return api.get(`${API}/active-framework`)
  },
  show: function(id) {
    return api.get(`${API}/${id}`)
  },
  update: function(params) {
    return api.update(`${API}/${params.id}`, params)
  },
  archive: function(params) {
    return api.update(`${API}/${params.id}/archive`, params)
  },
  calculateScores: function(id, form) {
    return api.update(`${API}/${id}/calculate-scores`, form )
  },
   getScoreByType: function(id, form) {
    return api.update(`${API}/${id}/get-score-by-type`, form )
  },
  publishAward: function(id, form) {
    return api.update(`${API}/${id}/publish-award`, form )
  },
  reOpenProject: function(id, form) {
    return api.update(`${API}/${id}/re-open-project`, form )
  },
  delete: function(params) {
    return api.delete(API + '/' + params.id)
  },
  import: function(params) {
    return api.create(`${API}/import`, params)
  },
}
