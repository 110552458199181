<template>
    <div v-if="form" class="" v-loading="loading">
        <el-form label-position="top">

            <div class="pb-0 card-header" style="text-align:center">
                <h5 class="font-weight-bolder mb-0">{{ title }}</h5>
                <hr>
            </div>

            <div v-if="!form.id">
                <el-steps style="margin-bottom: 50px" :active="step" align-center>
                    <el-step title="Contract" description="Determine the contract type" />
                    <el-step title="Project Type" description="" />
                    <el-step title="Details" description="Provide the project details" />
                    <el-step title="Final" description="Review the details" />
                </el-steps>

                <div v-if="step == 0" style="text-align: center;" class="row">
                    <div style="padding-top: 20px" class="col-md-3"></div>
                    <div style="padding-top: 20px" class="col-md-6">
                        <div>
                            <h5>What do you want to do ?</h5>

                        <div :class="{ 'has-error-field': v.form.project_category.$errors.length }">
                            <el-radio-group v-model="form.project_category">
                                <el-radio :value="0" size="large">Public Contracts</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.project_category.$errors.length" class="error-text">Select a contract</div>
                        </div>

                        <div style="padding-top: 20px" v-if="form.project_category >= 0">
                            <label class="large-label">What type of Procurement Project?</label>
                            <br>
                            <!-- <el-checkbox v-model="form.is_framework" :label="form.is_framework ? 'Standard Procurement' : 'Framework Agreement'" size="large"
                                @change="handleChangeCategory" /> -->
                            <div :class="{ 'has-error-field': v.form.project_type.$errors.length }">
                                <el-radio-group v-model="form.project_type">
                                    <el-radio :value="0" size="large">Standard Procurement</el-radio>
                                    <el-radio :value="1" size="large">Framework Agreement</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.project_type.$errors.length" class="error-text">Select a project type</div>    
                        </div>
                    </div>

                   
                </div>

                <div v-if="step == 1" style="text-align: center;">
                    <div class="" style="padding-top: 20px" v-if="form.project_type == 1">
                        <div>
                            <label class="large-label">Please select the type of Framework Agreement.</label>
                            <div :class="{ 'has-error-field': v.form.agreement_type.$errors.length }">
                                <el-radio-group v-model="form.agreement_type">
                                    <el-radio :value="0" size="large">Open Framework</el-radio>
                                    <el-radio :value="1" size="large">Closed Framework</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.agreement_type.$errors.length" class="error-text">Select an option</div>
                        </div>

                        <br>

                        <div v-if="form.agreement_type == 0" class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <label class="large-label">Select Recurring Interval.</label>
                                <div :class="{ 'has-error-field': v.form.recurring_interval.$errors.length }">
                                    <select class="form-control form-control-sm" v-model="recurring_interval">
                                        <option :value="1">1 month</option>
                                        <option :value="2">2 months</option>
                                        <option :value="3">3 months</option>
                                        <option :value="4">4 months</option>
                                        <option :value="5">5 months</option>
                                        <option :value="6">6 months</option>
                                        <option :value="7">7 months</option>
                                        <option :value="8">8 months</option>
                                        <option :value="9">9 months</option>
                                        <option :value="10">10 months</option>
                                        <option :value="11">11 months</option>
                                        <option :value="12">1 year</option>
                                        <option :value="24">2 years</option>
                                        <option :value="36">3 years</option>
                                        <option :value="48">4 years</option>
                                        <option :value="60">5 years</option>
                                        <option :value="72">6 years</option>
                                        <option :value="84">7 years</option>
                                        <option :value="96">8 years</option>
                                        <option :value="0">Custom</option>
                                        <option :value="999">I'll decide later</option>
                                    </select>
                                </div>
                                <div v-if="v.form.recurring_interval.$errors.length" class="error-text">Select an option</div>

                                <div v-if="recurring_interval == 0">
                                    <br>
                                    <div>
                                        <label class="large-label">Duration type</label>
                                        <div :class="{ 'has-error-field': v.form.duration_type.$errors.length }">
                                            <el-radio-group v-model="form.duration_type" >
                                                <el-radio :value="0" size="large">Months</el-radio>
                                                <el-radio :value="1" size="large">Years</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <div v-if="v.form.duration_type.$errors.length" class="error-text">Select an option</div>
                                    </div>
                                    <div>
                                        <label class="large-label">{{ form.duration_type == 0 ? 'How many months?':'How many years?' }}</label>
                                        <div :class="{ 'has-error-field': v.form.duration_value.$errors.length }">
                                            <el-input @input="forceInteger" type="number" v-model="form.duration_value" :step="1" :max="form.duration_type == 0 ? 12 : 99"></el-input>
                                        </div>
                                        <div v-if="v.form.duration_value.$errors.length" class="error-text">Select an option</div>
                                    </div>
                                    <br>

                                    <div v-if="form.duration_type >= 0 && form.duration_value > 0">
                                        <label class="large-label">Is there a chance that you will reopen before the stated interval ?</label><br>
                                        <el-checkbox v-model="form.can_re_open" :label="form.can_re_open ? 'Yes' : 'No'" size="large"
                                @change="handleChangeCategory" />
                                    </div>
                                </div>
                                <br>

                            </div>
                        </div>
                        <div  v-if="form.agreement_type >= 0" class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <label class="large-label">Choose Threshold</label>
                                <div :class="{ 'has-error-field': v.form.threshold.$errors.length }">
                                    <el-radio-group v-model="form.threshold" >
                                        <el-radio :value="0" size="large">Above-Threshold</el-radio>
                                        <el-radio :value="1" size="large">Below-Threshold</el-radio>
                                    </el-radio-group>
                                </div>
                                <div v-if="v.form.threshold.$errors.length" class="error-text">Select an option</div>
                            </div>
                        </div>

                    </div>

                    <div class="" style="padding-top: 20px" v-if="form.project_type == 0">
                        <div>
                            <label class="large-label">Please select the type of Standard Procurement.</label>
                            <div :class="{ 'has-error-field': v.form.standard_type.$errors.length }">
                                <el-radio-group v-model="form.standard_type" @change="changeFrameworkCategory">
                                    <el-radio :value="0" size="large">Open Procedure</el-radio>
                                    <el-radio :value="1" size="large">Competitive Flexible Procedure (CFP)</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.standard_type.$errors.length" class="error-text">Select an option</div>
                        </div>
                    </div>

                    <div class="" style="padding-top: 20px" v-if="form.project_type == 0 && form.standard_type >= 0">
                        <div>
                            <label>Choose Threshold</label>
                            <div :class="{ 'has-error-field': v.form.threshold.$errors.length }">
                                <el-radio-group v-model="form.threshold" >
                                    <el-radio :value="0" size="large">Above-Threshold</el-radio>
                                    <el-radio :value="1" size="large">Below-Threshold</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.threshold.$errors.length" class="error-text">Select an option</div>
                        </div>
                    </div>

                </div>

                <div v-if="step == 2">
                    <procurement-projects-form :selected="form"
                        @save="detail_form = $event"></procurement-projects-form>
                </div>

                <div v-if="step > 2 && detail_form" style="margin-left: 50px;margin-right: 50px">
                    <div class="row">
                        <div class="col-md-12">
                       
                            <div><strong>Project Name:</strong> {{ detail_form.name }}</div>
                            <div>
                                <div><strong>Project Type : </strong> {{ form.project_type == 0 ? 'Standard Procurement':'Framework Agreement' }}</div>
                                <div v-if="form.project_type == 0">
                                    <strong>{{ form.standard_type == 0 ? 'Open Procedure':'Competitive Flexible Procedure (CFP)' }} </strong> 
                                </div>
                                <div v-if="form.project_type == 1">
                                    <strong>{{ form.agreement_type == 0 ? 'Open Framework':'Closed Framework' }}</strong> <span v-if="form.agreement_type == 0">(Recurring interval: {{ $helpers.getRecurringIntervalLabel(form.recurring_interval) }})</span>
                                </div>
                                <div>(<span>{{ form.threshold == 0 ? 'Above-Threshold':'Below-Threshold'}}</span>)</div>
                            </div>
                           
                            <div>
                                <br>
                                {{ detail_form.description }}
                            </div>
                            <hr>
                        </div>
                        <div class="col-md-4">
                            <div><strong>Main Description</strong></div>
                            <div v-html="detail_form.main_description"></div>

                            <div><strong>Common Description</strong></div>
                            <div v-html="detail_form.common_description"></div>
                        </div>
                        <div class="col-md-4">
                            <div><strong>My Files Description</strong></div>
                            <div v-html="detail_form.my_files_description"></div>
                            <div><strong>My Questions Description</strong></div>
                            <div v-html="detail_form.my_questions_description"></div>
                        </div>
                        <div class="col-md-4">
                            <div><strong>Specification Description</strong></div>
                            <div v-html="detail_form.spec_description"></div>
                            <div><strong>Q&A Description</strong></div>
                            <div v-html="detail_form.qa_description"></div>
                        </div>
                    </div>
                </div>

                <div style="text-align: center">
                    <br>
                    <el-button-group>
                        <el-button type="primary" v-if="step >= 1" @click="handleBack"><i
                                class="fa fa-arrow-left"></i>Back</el-button>
                        <el-button type="primary" @click="handleValidate" v-if="step <= 2">
                            Next <i class="fa fa-arrow-right"></i>
                        </el-button>
                        <el-button type="primary" @click="handleValidate" v-if="step > 2">
                            Submit
                        </el-button>
                    </el-button-group>
                </div>
            </div>
        </el-form>
    </div>

</template>

<script>
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required
} from '@vuelidate/validators'
import { f } from 'vue-search-select';
import ProcurementProjectsForm from './ProcurementProjectsForm.vue';
import { ProcurementProjects } from '@/api_services/procurement_projects';
import Swal from 'sweetalert2';


export default {
    setup: () => ({
        v: useVuelidate()
    }),
    props: {
        selected: {
            type: Object,
            required: false
        }
    },
    components: {
        ProcurementProjectsForm
    },
    data() {
        return {
            first_step_1: true,
            step: 0,
            form: {
                recurring_interval: 1,
                is_framework: true,
                framework_category: 1
            },
            procurement_types: [],
            detail_form: {},
            recurring_interval: 1,
            duration_type: 0,
            duration_value: 0,
            submit: 0,
            loading: false
        }
    },
    validations() {
        let form = {
            project_category: {
                required,
                $autoDirty: true
            },
        }

        if (this.step == 0) {
            form.project_type = {
                required,
                $autoDirty: true
            }
        }

        if (this.step == 1) {
           
            if (this.form.project_type == 0) {
                form.standard_type = {
                    required,
                    $autoDirty: true
                }

                form.threshold = {
                    required,
                    $autoDirty: true
                }
            }

            if (this.form.project_type == 1) {
                form.agreement_type = {
                    required,
                    $autoDirty: true
                }

                form.recurring_interval = {
                    required,
                    $autoDirty: true
                }

                if (this.form.agreement_type == 0 && this.recurring_interval == 0) {
                    form.duration_type = {
                        required,
                        $autoDirty: true
                    }

                    form.duration_value = {
                        required,
                        $autoDirty: true
                    }
                }

                if (this.form.agreement_type >= 0) {
                    form.threshold = {
                        required,
                        $autoDirty: true
                    }
                }
            }           
        }

        if (this.step == 1 && this.form.is_framework) {
            form.framework_category = {
                required,
                $autoDirty: true
            }

            if (this.form.framework_category == 2) {
                form.category = {
                    required,
                    $autoDirty: true
                }
            }
        }

        if (this.step == 1 && !this.form.is_framework) {
            form.procedure_type = {
                required,
                $autoDirty: true
            }
        }

        return { form }
    },
    created: function () {
        this.init()
        this.getProcurementProjectTypes()
        if (this.selected) this.form = Object.assign({}, this.selected)
        if (!this.form.id) {
            this.form.recurring_interval = 1
            this.form.is_framework = true
            this.form.framework_category = 1
            this.recurring_interval = 12
            this.form.recurring_interval = this.recurring_interval
        }
    },
    watch: {
        selected() {
            this.form = {}
            if (this.selected) this.form = Object.assign({}, this.selected)
            if (!this.form.id) this.form.recurring_interval = 1
        },
        recurring_interval() {
            this.form.recurring_interval = this.recurring_interval
        }
    },
    computed: {
        title() {
            return this.isNew ? 'New Project' : `Manage Project`
        },
        isNew() {
            return !this.selected?.id
        },
        typeLabel() {
            let label = ""
            let self = this
            this.procurement_types.forEach(function (type) {
                if (self.form.type == type.type_code) label = type.type
                if (self.detail_form && self.detail_form.type == type.type_code) label = type.type
            })
            return label
        }
    },
    methods: {
        forceInteger() {
            this.form.duration_value = parseInt(this.form.duration_value) || 0;
            let limit = this.form.duration_type == 0 ? 240 : 20
            if (this.form.duration_value > limit) {
                this.form.duration_value = limit;
            }
        },
        changeFrameworkCategory(){
            if (this.form.framework_category < 2) this.form.category = this.form.framework_category
        },
        showRecurringInterval(){
            if (this.form.framework_category == 1) return true
            if (this.form.framework_category == 2 && this.form.category == 1) return true

            return false
        },
        init(){
            this.form = { is_framework: true}
        },
        getProcurementProjectTypes() {
            ProcurementProjects.getProcurementProjectTypes()
                .then(resp => {
                    this.procurement_types = resp.data.procurement_project_types
                })
                .catch(error => {
                    this.$toast.error('An error occured while getting procurement project types')
                    console.error('error:', error.response.data)
                })
        },
        handleChangeCategory() {
            this.first_step_1 = false
        },
        handleBack() {
            if (this.step > 1) this.first_step_1 = false
            this.step -= 1
            if (this.step == 2) {
                this.form = Object.assign({}, this.detail_form)
            }
        },
        async handleValidate(evt) {

            evt.preventDefault()

            const result = await this.v.$validate()
            if (!result) return

            if (this.step > 2) {
                this.beforeSubmit()
                return
            }

            if (this.recurring_interval == 0 && this.form.project_type == 1 &&  this.form.agreement_type == 0) {
                this.form.recurring_interval = this.form.duration_type == 1 ? this.form.duration_value * 12 : this.form.duration_value
            } else {
                this.form.recurring_interval = this.recurring_interval
            }

            this.step += 1
        },
        beforeSubmit(){
            Swal.fire({
            title: 'Review',
            text: 'You are about to create a new project. Please verify that all the details are accurate. Would you like to proceed?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
                this.handleSubmit()
            }
          });
        },
        handleSubmit(){
            this.loading = true
            let form = Object.assign({}, this.detail_form)
            form.recurring_interval = this.form.recurring_interval
            form.category = this.form.framework_category < 2 ? this.form.framework_category : this.form.category
            form.project_type = this.form.project_type
            form.standard_type = this.form.standard_type
            form.agreement_type = this.form.agreement_type
            form.threshold = this.form.threshold
            form.can_re_open = this.form.can_re_open

            ProcurementProjects.create(form)
            .then(resp => {
                this.step = 0
                this.form = {}
                this.detail_form = null
                this.$toast.success(resp.data.message || 'Procurement Project has been saved successfully')
                this.init()
                this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
            .finally(() => {
              this.loading = false
            })
        }
    }
}
</script>