<template>
  <div>

    <div v-if="!pin_notice && $loading">

    </div>
    <div v-else-if="!pin_notice">

    </div>
    <div class="row" v-else>
      <div>
        <el-alert v-if="notification" :title="notification.message" type="success" effect="dark">
        </el-alert>
        
        <div class="row align-items-center">
          <div class="col-md-10 mb-2">
            <h3 class="" :class="!selected_framework ? 'text-white' : ''">
              {{ (view_type ? "(" + (view_type == 'PIN' ? 'Planned Procurement Notice' : 'Tender Notice') +")" : "") }} {{ pin_notice.name }}
            </h3>
          </div>
          <div class="col-md-2 mb-2">

            <el-button-group class="float-end" v-if="$store.state.isAdmin() || (selected_filter == ProjectFilters.FILTER_ALL &&
            (
              pin_notice?.user?.id != user.id &&
              user?.company?.id != pin_notice?.company?.id &&
              !company_ids.includes(pin_notice?.company?.id)
            ))">
            <el-button v-if="$store.state.checkUserGroupPermissions('ppn-details', 'join', true) && (!pin_notice.if_user_joined &&
    (pin_notice.status == PinNoticeStatuses.ACTIVE || pin_notice.project_status == ProcurementProjectStatuses.CODE_PRE_PIN) &&
    pin_notice.is_deadline_passed == 0 &&
    (pin_notice.project_status != ProcurementProjectStatuses.CODE_DOC_FINALIZE && pin_notice.project_status != ProcurementProjectStatuses.CODE_ITT_PENDING))
    " type="primary" size="large" @click="joinOrLeavePin('join')">
                            <i class="fa fa-door-open"></i>&nbsp;Join PPN</el-button>

                            <el-button  v-if="(pin_notice.if_user_joined && pin_notice.if_user_joined.pin_leave_request == 0) &&
    (pin_notice.status == PinNoticeStatuses.ACTIVE || pin_notice.project_status == ProcurementProjectStatuses.CODE_PRE_PIN) &&
    pin_notice.is_deadline_passed == 0 &&
    (pin_notice.project_status != ProcurementProjectStatuses.CODE_DOC_FINALIZE && pin_notice.project_status != ProcurementProjectStatuses.CODE_ITT_PENDING)
    " type="danger" size="large" @click="joinOrLeavePin('leave')">
                            <i class="fa fa-door-closed"></i>&nbsp;Leave PPN</el-button>
                        
                        </el-button-group>
          </div>
        </div>

        <div class="card my-3">
          <div class="card-body">
            <div v-html="pin_notice.main_description"></div>
          </div>
        </div>

        <el-drawer v-model="showDrawer" :with-header="true" :showClose="false" direction="rtl" size="80%">
          <!-- drawer content here -->
          <supplier-hub-welcome></supplier-hub-welcome>
          <div class="text-center">
            <el-button class="btn btn-warning" type="warning" @click="showDrawer = false">CLOSE</el-button>
          </div>
        </el-drawer>
      </div>
      <div>
        <el-button size="default" v-if="(!forum_related_data.user_forum_related_info || forum_related_data.user_forum_related_info.length == 0) && ($store.state.isUserASupplier() || $store.state.isUserABuyer() || $store.state.isAdmin()) "
         @click="createThread(pin_notice)" class="mb-3 float-start" type="success" >
            <i class="fa fa-edit"></i>&nbsp;Create a Thread
          </el-button>

        <el-button type="primary" @click="handleViewSpecsList()" v-tooltip="'View Specifications'"
          v-if="$store.state.checkUserGroupPermissions(current_view_type, 'view_framework_lots', true) && (pin_notice.project_status != 'PEN' && pin_notice.project_status != 'SUB')">View Framework
          Lots</el-button>

        <el-button type="success" @click="viewSuppliers()" v-tooltip="'View Suppliers'"
        v-if="$store.state.checkUserGroupPermissions('ppn-details', 'view_suppliers', false, false, '', '', ['buyer', 'originator'])"
        >View Suppliers</el-button>

        <el-button type="warning" @click="invitation_drawer = true" v-tooltip="'View External Invitations'"
        v-if="$store.state.checkUserGroupPermissions(current_view_type, 'view_external_invitations', false, false, '', '', ['buyer', 'originator'])">View External Invitations</el-button>

        <el-button v-if="
            view_itt == 1 && 
            pin_notice?.itt_framework?.id &&
            pin_notice?.itt_framework?.status != IttStatuses.PENDING
            " type="success" @click="viewITT()" v-tooltip="'View Tender Notice'">
          <i class="fa fa-eye"></i>&nbsp;View Tender Notice</el-button>


      </div>
      <div>

      </div>
      
      <div v-if="$store.state.checkUserGroupPermissions(current_view_type, 'essential_framework_files', false, true, 'common_files', 'view')" class="col-md-6">
        <common-files :if_pin_notice="1" :itt_framework="pin_notice"></common-files>
      </div>
      <div v-if="$store.state.checkUserGroupPermissions(current_view_type, 'my_file_library', false, true, 'my_files', 'view')" class="col-md-6">
        <my-files :if_pin_notice="1" :itt_framework="pin_notice"></my-files>
      </div>

      <div v-if="$store.state.checkUserGroupPermissions(current_view_type, 'qa_board', false, true, 'questions', 'view')" class="col-md-6">
        <public-questions-with-answers-list :if_pin_notice="1"
          :itt_framework="pin_notice"></public-questions-with-answers-list>
      </div>

      <div v-if="$store.state.checkUserGroupPermissions(current_view_type, 'my_question', false, true, 'questions', 'view')" class="col-md-6">
        <my-questions-list :if_pin_notice="1" :itt_framework="pin_notice"></my-questions-list>
      </div>

      <div v-if="$store.state.checkUserGroupPermissions('ppn-details', 'related_forum_threads', true)" class="col-md-12">
        <ForumRelatedInformationList
          :data="forum_related_data.forum_related_info"
          :title="'Threads Related to this Planned Procurement Notice'"
        ></ForumRelatedInformationList>
      </div>
    </div>
  </div>

  <el-drawer v-model="showSpecsList" :showClose="true" size="70%" :with-header="true" direction="rtl"
    :destroy-on-close="true" :append-to-body="true">
    <div class="container">
      <supplierhub-lots-list :if_pin_notice="1" :itt_framework="pin_notice"></supplierhub-lots-list>
    </div>
  </el-drawer>

  <el-drawer v-model="showITTDashboard" :show-close="true" size="80%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div v-if="itt_framework" class="container">
      <supplier-hub-framework-view :view_type="'PIN'" :selected_framework="itt_framework"></supplier-hub-framework-view>
    </div>
  </el-drawer>

  <el-drawer v-model="supplier_drawer" :show-close="true" size="70%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div class="container">
      <SupplierList :pin_notice="pin_notice" :project_id="pin_notice.procurement_project_id" :id="pin_notice.id" :type="'pin'"></SupplierList>
    </div>
  </el-drawer>

  <el-drawer v-model="invitation_drawer" :show-close="true" size="70%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div class="container">
      <ExternalInvitationImport :show_invite_tab="false"
      :invitation_list="external_invitations" :button_size="'default'" :add_text="'Add External User Email'" :tag_size="'large'"
      @update="getSelectedExternalUser"></ExternalInvitationImport>
    </div>
  </el-drawer>

  <el-dialog id="modal-leave" v-model="leave_modal" title="Leave this PPN?" width="500">
    <div v-if="pin_notice">
      You are about to leave the pin for the project: {{ pin_notice.name }}, Proceed?
      <br>
      <label class="h6 mt-3">Reason:</label>
      <el-input autosize type="textarea" class="mt-1" v-model="leave_reason" placeholder="Reason here..." />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="leave_modal = false">Cancel</el-button>
        <el-button type="danger" @click="leavePin(pin_notice)">
          Confirm
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-drawer v-model="thread_drawer" :show-close="true" size="70%" direction="rtl"
    :append-to-body="true">
    <div class="container">
      
      <ForumThreadForm :prop_selected_related_info="selected_related_info" :key="'thread_form_' + thread_form_counter" :show_category_selection="true" 
      :selected="selected_thread" @save="afterThreadSave" @cancel="thread_drawer = false">
      </ForumThreadForm>
    </div>
  </el-drawer>

</template>
    
    <script>
    import MyQuestionsList from '../questions_and_answers/MyQuestionsList.vue'
    import PublicQuestionsWithAnswersList from '../questions_and_answers/PublicQuestionsWithAnswersList.vue'
    import CommonFiles from '../SuppliersHub/CommonFiles.vue'
    import MyFiles from '../SuppliersHub/MyFiles.vue'
    import SupplierHubWelcome from '../SuppliersHub/SupplierHubWelcome.vue'
    import SupplierhubLotsList from '../SuppliersHub/SupplierhubLots/SupplierhubLotsList.vue'
    import { defineAsyncComponent } from 'vue'
    import SupplierHubFrameworkView from '../SuppliersHub/SupplierHubFrameworkView.vue'
    import { IttFramework } from '@/api_services/itt_framework'
    import { mapState } from 'vuex'
    import SupplierList from '../procurement_projects/SupplierList.vue'
    import ExternalInvitationImport from '@/components/common/ExternalInvitationImport.vue'
    import {
      ProcurementProjectParticipants
    } from '@/api_services/procurement_project_participants'
    import Swal from 'sweetalert2'
    import ForumThreadForm from '../forum/ForumThreadForm.vue'
    import ForumRelatedInformationList from '../forum/ForumRelatedInformationList.vue'

    export default {
      props: {
        external_invitations: {
          type: Object,
          required: false,
          default: []
        },
        selected_framework: {
          type: Object,
          required: false,
          default: null
        },
        view_type: {
          type: String,
          required: false,
          default: ""
        },
        view_itt: {
          type: Number,
          required: false,
          default: 0
        },
        company_ids: {
          type: Array,
          required: false,
          default: []
        },
        selected_filter: {
          type: String,
          required: false,
          default: "all"
        },
        forum_related_data: {
          type: Object,
          required: false,
          default: {}
        },
      },
      computed: {
        ...mapState(['IttStatuses', 'ProcurementProjectStatuses', 'PinNoticeStatuses', 'ProjectFilters']),
        user() {
          return this.$store.state.savedUser // get state
        },
        current_view_type(){
          if(!this.view_type){
            return "ppn-details"
          }
          else{
            if(this.view_type == "PIN"){
              return "ppn-details"
            }
            else{
              return "tn-details"
            }
          }
        }
      },
      watch: {
        selected_framework: function() {
          if (this.selected_framework) {
            this.pin_notice = Object.assign({}, this.selected_framework)
          }
        },
        forum_related_data: {
          handler(newValue) {
            if(this.pin_notice){
              this.selected_related_info = []
              let key_object = {}
              let id_object = { id: this.pin_notice?.id };
              key_object["key"] = id_object
              key_object["model_id"] = newValue.model_info.id

              this.selected_related_info.push(key_object)
            }
          },
          deep: true
        }
      },
      
      components: {
        SupplierList,
        CommonFiles,
        MyFiles,
        PublicQuestionsWithAnswersList,
        MyQuestionsList,
        SupplierHubWelcome,
        SupplierhubLotsList,
        UserIttFrameworksList: defineAsyncComponent(() =>
          import('../itt_frameworks/UserIttFrameworksList.vue')
        ),
        SupplierHubFrameworkView,
        ExternalInvitationImport,
        ForumThreadForm,
        ForumRelatedInformationList
      },
      data() {
        return {
          selected_related_info: [],
          selected_thread: null,
          thread_form_counter: 1,
          thread_drawer: false,
          leave_reason: null,
          leave_modal: false,
          invitation_drawer: false,
          supplier_drawer: false,
          itt_framework: null,
          showITTDashboard: false,
          showSpecsList: false,
          showFrameworks: false,
          showDrawer: false,
          drawer: false,
          notification: null,
          loading: true,
          pin_notice: null,
          join_form: {
            participation_type: null,
            procurement_project_id: null,
            leave_request_type: null,
            reason: null,
            if_admin_rejected: null,
            if_leave_immediately: null
          },
        }
      },
      created: function () {
        if (this.selected_framework) {
          this.pin_notice = Object.assign({}, this.selected_framework)
        }
        if (this.forum_related_data.model_info) {
          this.selected_related_info = []
          let key_object = {}
          let id_object = { id: this.pin_notice?.id };
          key_object["key"] = id_object
          key_object["model_id"] = this.forum_related_data?.model_info?.id

          this.selected_related_info.push(key_object)
        }
      },
      methods: {
        afterThreadSave(){
          this.thread_form_counter++
          this.thread_drawer = false
          window.location.href = "/main/planned_procurement_notices?target=" + btoa(this.pin_notice.id)
        },
        createThread(pin_notice){
          this.selected_thread = {
            title: "PPN: " + pin_notice.name,
            content: null,
            tag: [],
            attachments: null
          }
          this.thread_drawer = true
        },
        leavePin(pin_notice) {
          if (this.leave_reason && this.leave_reason !== "") {
            Swal.fire({
              title: 'Leave this PPN?',
              text: 'By clicking continue, you will be leaving this PPN. Do you want to proceed?',
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Continue',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                this.globalState.loading = true

                this.join_form.participation_type = "pin"
                this.join_form.procurement_project_id = pin_notice.procurement_project.id
                this.join_form.leave_request_type = 0
                this.join_form.reason = this.leave_reason
                this.join_form.if_leave_immediately = 1

                ProcurementProjectParticipants.leaveParticipant(pin_notice.id, this.join_form)
                  .then(result => {
                    this.$toast.success(result.data.message)
                    window.location.href = "/main/planned_procurement_notices?target=" + btoa(pin_notice.id)
                  })
                  .catch(error => {
                    console.log(error)
                  })
                  .finally(() => {
                    this.globalState.loading = false
                  })

              }
            });
          } else {
            this.$toast.warning("Please enter a reason for leaving.")
          }
        },
        joinPin(pin_notice) {
          Swal.fire({
            title: 'Do you want to join?',
            text: 'By clicking continue, you will be joining this Planned Procurement Notice. Do you want to proceed?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              this.globalState.loading = true

              this.join_form.participation_type = "pin"
              this.join_form.procurement_project_id = pin_notice.procurement_project.id

              ProcurementProjectParticipants.joinParticipant(pin_notice.id, this.join_form)
                .then(result => {
                  this.$toast.success(result.data.message)
                  window.location.href = "/main/planned_procurement_notices?target=" + btoa(pin_notice.id)
                })
                .catch(error => {
                  console.log(error)
                })
                .finally(() => {
                  this.globalState.loading = false
                })

            }
          });
        },
        joinOrLeavePin(type){
          if(!this.$store.state.isUserASupplier()){
            this.$emit('join_or_leave_pin', type)
          }
          else{
            if(type == "join"){
              this.joinPin(this.pin_notice)
            }
            else if(type == "leave"){
              this.leave_modal = true
            }
          }
        },
        viewSuppliers() {
          this.supplier_drawer = true
        },
        viewITT(){
          this.itt_framework = Object.assign({}, this.pin_notice.itt_framework)
          this.showITTDashboard = true
        },
        handleViewSpecsList() {
          this.showSpecsList = true
        },
        handleViewFrameworks() {
          this.showFrameworks = true
        }
      }
    }
    </script>
    